import * as React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Accordion from "../components/Accordion"
import {
  WrapperLight,
  WrapperDark,
  WrapperDarkGreen,
  Content,
  WrapperLightGreen,
  Banner,
} from "../components/section-styling"
import { ButtonLink } from "../components/ButtonLink"

import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import { breakpoints } from "../utils/breakpoints"

const ServicesContent = styled(Content)`
  @media ${breakpoints.md} {
    flex-flow: row nowrap;
  }
  flex-flow: row wrap;
  align-items: flex-start;

  a {
    color: black;
    transition: color 300ms;

    :hover {
      color: var(--teal);
    }
  }

  .services-link:focus {
    color: var(--teal);
  }

  a:focus {
    border-style: none;
  }
`

const ServicesItemContent = styled(Content)`
  flex-flow: column nowrap;
  justify-content: flex-start;

  align-items: flex-start;
  padding: 3rem 1rem;

  p.hasChild {
    margin-bottom: 0.5rem;
  }
`

const ServicesPage = () => (
  <Layout>
    <Seo title="Services" />

    <WrapperLight>
      <ServicesContent>
        <div>
          <h2>We take the complex and make it simple.</h2>
          <p>
            We have the wisdom to guide you in creating technology with the most
            impact.
          </p>
        </div>

        <div style={{ flexGrow: 2, flexShrink: 0 }}>
          <AnchorLink
            to="/services#consulting"
            title="Consulting"
            className="services-link"
          >
            <Accordion title="Consulting" text=""></Accordion>
          </AnchorLink>

          <AnchorLink
            to="/services#design"
            title="Design"
            className="services-link"
          >
            <Accordion title="Design" text=""></Accordion>
          </AnchorLink>

          <AnchorLink
            to="/services#custom"
            title="Custom Software"
            className="services-link"
          >
            <Accordion title="Custom Software" text=""></Accordion>
          </AnchorLink>

          <AnchorLink
            to="/services#research"
            title="Social Computing Research"
            className="services-link"
          >
            <Accordion title="Social Computing Research" text=""></Accordion>
          </AnchorLink>

          <AnchorLink
            to="/services#cms"
            title="Content Management Systems"
            className="services-link"
          >
            <Accordion title="Content Management Systems" text=""></Accordion>
          </AnchorLink>

          <AnchorLink
            to="/services#hosting"
            title="Hosting and Support"
            className="services-link"
          >
            <Accordion title="Hosting and Support" text=""></Accordion>
          </AnchorLink>
        </div>
      </ServicesContent>
    </WrapperLight>

    <WrapperDarkGreen>
      <ServicesItemContent id="consulting">
        <h2>Consulting</h2>
        <p className="hasChild">
          Members of our small-but-mighty Sassafras team have an average of over
          a <i>decade</i> of experience each across industry, academia, and
          freelancing.
        </p>

        <p>
          We bring expertise in website & app design, engineering, and funding
          strategies for making sustainable, useful technology. Design begins in
          our first conversation, where we start to understand your needs. From
          there we provide consulting, research, design and technical plans,
          quality assurance, and budgets. This includes advising when you{" "}
          <i>shouldn't</i> build a technology solution, and providing
          alternatives that are usable and sustainable.
        </p>

        <Accordion
          title="Tech consulting"
          text="We can help you architect the tech solution for your needs. Our technical plans always include specific tool recommendations, and solutions for the trickiest parts. We have architected sensitive and complex data integrations, multitenant applications, and novel software other consultants might consider 'impossible' on small budgets. Data modeling needs? Custom API design? We can do that too. We will draw on our experiences creating and sustaining software applications that have served organizations for over a decade."
        ></Accordion>

        <Accordion
          title="Movement-aligned strategies"
          text="We have the experience and connections to guide you through the best movement-aligned technology solutions. We prioritize budget, sustainability, and users' safety. We have experience navigating tech needs for collaborations across organizations, and designing software that supports grassroots, bottom-up decision making."
        ></Accordion>

        <Accordion
          title="Divestment"
          text="Google, Microsoft, and Amazon are fueling massacres in Gaza, violating our privacy, selling our data, and exploiting workers on a massive scale. We can help you prune off big tech tools and shift to open-source and/or cooperative-built alternatives."
        ></Accordion>

        <Accordion
          title="Grants and funding"
          text="We have won funding across industry, academia, and foundations &ndash; such as the National Science Foundation (NSF) and the Ford Foundation. We can help you fund your dreams. Our estimates and budgeting make the most of available resources, making sure that your project's priorities are front and center."
        ></Accordion>

        <Accordion
          title="Narrative, branding, and translations"
          text="We can help you tell your story. In multiple languages."
        ></Accordion>
      </ServicesItemContent>
    </WrapperDarkGreen>

    <WrapperLight>
      <ServicesItemContent id="design">
        <h2>Design</h2>
        <p className="hasChild">
          Sassafras transforms research knowledge and anti-oppressive theory
          into impactful real-world design. Using design justice principles, we
          act as facilitators to uplift and honor community knowledge to inform
          the design and development of digital systems.
        </p>
        <p>
          We have years of experience with various design tools and techniques,
          including heuristics evaluations, comparative analyses, and user
          experience reviews.
        </p>
        <Accordion
          title="Mobile design"
          text="15% of US adults don't have home broadband, relying on only their smartphone &ndash; so it's important to have a website that doesn't just function, but looks great on mobile."
        ></Accordion>
        <Accordion
          title="Accessibility"
          text="27% of US adults are disabled in some way. We have the knowledge and ability to deliver accessible web experiences."
        ></Accordion>
      </ServicesItemContent>
    </WrapperLight>

    <WrapperDark>
      <ServicesItemContent id="custom">
        <h2>Custom Software</h2>
        <p className="hasChild">
          Doing something no one else has done before? We'll join your team to
          help you build the app of your dreams. We can build something from
          scratch and/or connect various software services via API. We use
          technologies that make the most sense for the job such as Ruby on
          Rails, Node, React, Vue, or Jekyll.
        </p>
        <p>
          We'll work with your budget and constraints to figure out what kind of
          software is best, both for more immediate needs and also for longer
          term sustainability and maintenance.
        </p>
      </ServicesItemContent>
    </WrapperDark>

    <WrapperLight>
      <ServicesItemContent id="research">
        <h2>Social Computing Research</h2>
        <p>
          We are scholars in social computing research communities and have
          research skills to generate new knowledge on the mutual shaping
          properties of people and technology. Our members have depth and
          breadth of experience in quantitative methods and statistics,
          literature reviews, and qualitative research (interviews and
          co-design).
        </p>
      </ServicesItemContent>
    </WrapperLight>

    <WrapperDarkGreen>
      <ServicesItemContent id="cms">
        <h2>Content Management Systems</h2>
        <p>
          From blogs to plug-ins to online classes, we'll find the right tools
          for you and your organization to share your brilliance with the world.
        </p>
      </ServicesItemContent>
    </WrapperDarkGreen>

    <WrapperLight>
      <ServicesItemContent id="hosting">
        <h2>IT, devops, & maintenance</h2>
        <p>
          We have a track record of keeping legacy software and database systems
          running smoothly for more than a decade. We can give your app the love
          it needs to stay functional and secure for the long term.
        </p>
      </ServicesItemContent>
    </WrapperLight>

    <WrapperDark>
      <ServicesItemContent id="hosting">
        <h2>Hosting and Support</h2>
        <p>
          We have the expertise to set up stable hosting and support to make
          sure you have what you need when you need it. Our responsive support
          means you won't have to worry about downtime or upgrades.
        </p>
      </ServicesItemContent>
    </WrapperDark>

    <WrapperLightGreen>
      <Banner>
        <h2>Have a project in mind?</h2>
        <ButtonLink to="/contact" className="light long">
          TELL US MORE
        </ButtonLink>
      </Banner>
    </WrapperLightGreen>
  </Layout>
)

export default ServicesPage
